import Vue from 'vue'

import ElementUI from 'element-ui'
import './styles/element-variables.scss'  //引入element的样式修改表

import './styles/szyudiao.scss'  // 引入样式表

import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')