import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/* 引入布局 */
import Layout from '../layout/index.vue'


const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: () => import('../views/index.vue'),
        name: 'Index',
        meta: { title: '网站首页' }
      },
      {
        path: '/about/:tg',
        component: () => import('../views/about.vue'),
        name: 'AboutTg',
        meta: { title: '关于我们' }
      },
      {
        path: '/about',
        component: () => import('../views/about.vue'),
        name: 'About',
        meta: { title: '关于我们' }
      },
      {
        path: '/product',
        component: () => import('../views/product.vue'),
        name: 'Product',
        meta: { title: '佳作欣赏' }
      },
      {
        path: '/news/:tg',
        component: () => import('../views/news.vue'),
        name: 'NewsTg',
        meta: { title: '行业动态' }
      },
      {
        path: '/news',
        component: () => import('../views/news.vue'),
        name: 'News',
        meta: { title: '行业动态' }
      },
      {
        path: '/zhuanjia',
        component: () => import('../views/zhuanjia.vue'),
        name: 'Zhuanjia',
        meta: { title: '专家视点' }
      },
      {
        path: '/yudiao',
        component: () => import('../views/yudiao.vue'),
        name: 'Yudiao',
        meta: { title: '玉雕论丛' }
      },
      {
        path: '/dashi',
        component: () => import('../views/dashi.vue'),
        name: 'Dashi',
        meta: { title: '会员风采' }
      },
      {
        path: '/video',
        component: () => import('../views/video.vue'),
        name: 'Video',
        meta: { title: '子冈杯' }
      },
      {
        path: '/contant/:tg',
        component: () => import('../views/contant.vue'),
        name: 'ContantTg',
        meta: { title: '联系我们' }
      },
      {
        path: '/contant',
        component: () => import('../views/contant.vue'),
        name: 'Contant',
        meta: { title: '联系我们' }
      },
      {
        path: '/proshow/:id',
        component: () => import('../views/proshow.vue'),
        name: 'Proshow',
        meta: { title: '作品内容' }
      },
      {
        path: '/dshow/:id',
        component: () => import('../views/dshow.vue'),
        name: 'DsShow',
        meta: { title: '大师内容' }
      },
      {
        path: '/newsshow/:cid/:id',
        component: () => import('../views/newsshow.vue'),
        name: 'NewsShow',
        meta: { title: '资讯内容' }
      },
      {
        path: 'search/:keyword',
        component: () => import('../views/search.vue'),
        name: 'Search',
        meta: { title: '搜索' }
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  history: true,
  mode: 'history'
})

export default router
