<template>
  <div class="app-wrapper">
    <div class="content">
      <div class="head">
        <div class="top">
          <div class="topcot">
            <div class="topleft">欢迎光临苏州市玉石雕刻行业协会！</div>
            <div class="topright">
              <div class="trleft"><a href="/contant/ly"><img src="../assets/1.png" alt="">在线留言</a></div>
              <input type="text" v-model="keyword" placeholder="请输入搜索内容">
              <button @click="searchs"></button>
            </div>
          </div>
        </div>

        <div class="toplogo">
          <div class="logo"><img src="../assets/logo.png" alt=""></div>
          <div class="tel"><img src="../assets/tel.png" alt=""></div>
        </div>
        
        <div class="szy_nav">
          <ul class="nav_list">
            <li class="highlight">
              <router-link :to="{ name:'Index' }" class="nav_head"><span>网站首页</span></router-link>
              <router-link :to="{ name:'Index' }" class="items">网站首页</router-link>
            </li>
            <li class="highlight">
              <router-link :to="{ name:'About' }" @click.native="flushCom('1')" class="nav_head"><span>关于我们</span></router-link>
              <router-link :to="{ path:'/about/1'}" @click.native="flushCom('1')" class="items">协会成员</router-link>
              <router-link :to="{ path:'/about/2'}"  @click.native="flushCom('2')" class="items">协会简介</router-link>
              <router-link :to="{ path:'/about/3'}"  @click.native="flushCom('3')" class="items">协会章程</router-link>
              <router-link :to="{ path:'/about/4'}"  @click.native="flushCom('4')" class="items">申请入会</router-link>
            </li>
            <li class="highlight">
              <router-link to="#" class="nav_head"><span>佳作珍赏</span></router-link>
              <router-link to="#" class="items">佳作珍赏</router-link>
            </li>
            <li class="highlight">
              <router-link :to="{ name:'News' }" @click.native="flushCom('hydt')" class="nav_head"><span>行业动态</span></router-link>
              <router-link :to="{ path:'/news/hydt'}" @click.native="flushCom('hydt')" class="items">行业动态</router-link>
              <router-link :to="{ path:'/news/zxhq'}" @click.native="flushCom('zxhq')" class="items">资讯行情</router-link>
              <router-link :to="{ path:'/news/ydwh'}" @click.native="flushCom('ydwh')" class="items">玉雕文化</router-link>
              
            </li>
            <li class="highlight">
              <router-link :to="{ name:'Yudiao' }" class="nav_head"><span>苏州玉器</span></router-link>
              <router-link :to="{ name:'Yudiao' }" class="items"><span>苏州玉器</span></router-link>
            </li>
            <li class="highlight">
              <router-link to="/dashi" class="nav_head"><span>会员风采</span></router-link>
              <router-link to="/dashi" class="items">会员风采</router-link>
            </li>
            <li class="highlight">
              <router-link to="#" class="nav_head"><span>子冈杯</span></router-link>
              <router-link to="#" class="items">子冈杯</router-link>
            </li>
            <li class="highlight">
              <router-link :to="{ name:'Contant' }" @click.native="flushCom('contant')" class="nav_head"><span>联系我们</span></router-link>
              <router-link :to="{ name:'Contant'}" @click.native="flushCom('contant')" class="items">联系方式</router-link>
              <router-link :to="{ path:'/contant/ly'}" @click.native="flushCom('liuyan')" class="items">在线留言</router-link>
            </li>
          </ul>
          <div class="shade"></div>
        </div>
      </div>

      <router-view ref="square"></router-view>
    </div>

    <div class="foot">
      <div class="footline"></div>
      <div class="footlogo">
        <div class="ftlgleft">
          <img src="../assets/footlogo.jpg" alt="" class="ftp1">
          <img src="../assets/9.png" alt="" class="ftp2">
        </div>
        <div class="ftlgright">
          <p>联系 苏州市玉石雕刻行业协会</p>
          <p>服务电话：0512-67511005</p>
          <p>传真：0512-67541506</p>
        </div>
      </div>
      <div class="footbk">
        <div class="fbkcon">
          <div class="fbkleft">
            <p>苏州市玉石雕刻行业协会 https://www.szyq.org.cn</p>
            <p>本网站设计内容解释所有权归苏州市玉石雕刻行业协会所有 请勿建立镜像</p>
            <p>
              <a href='https://beian.miit.gov.cn/' target='_blank'>苏ICP备2022039260号</a> &nbsp;&nbsp; 
              <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050802011602">
                <img src="../assets/beianbianhao.png"/>
                苏公网安备 32050802011602号
              </a>
            </p>
          </div>
          <div class="fbkright">
            <p>
              <a href="/index">网站首页</a> | 
              <a href="/about">关于我们</a> | 
              <a href="#">佳作珍赏</a> | 
              <a href="/news">行业动态</a> | 
              <a href="/yudiao">苏州玉器</a> | 
              <a href="/dashi">会员风采</a> | 
              <a href="#">子冈杯</a> | 
              <a href="contant">联系我们</a>
            </p>
            <p>地址：苏州市西北街88号 邮编：215001 E-mail：2765168095@qq.com</p>
            <p>技术支持：苏州苏作天工大数据服务有限公司</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/display.css'
export default {
  name: 'Layout',
  data() {
    return {
      szgsearch: '',
      activeIndex: 'Index',
      keyword: ''
    }
  },
  methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
      flushCom(to) {
        console.log(to)
        this.$refs.square.changeActiveName(to)
      },
      searchs() {
        if(this.keyword.length>0) {
        location.href='/search/' + this.keyword;
      }
    }
    }
}
</script>
<style scoped>

</style>